import { styled, TextField } from "@mui/material";

const StyledTextField = styled(TextField)({
    fontFamily: "Montserrat, sans-serif",
    margin: "8px 0",
    width: "100%",

    "& .MuiOutlinedInput-root": {
        color: "white",
    },

    "& .MuiInputLabel-root": {
        color: "rgba(154, 152, 166, 0.8)",
    },

    "& .MuiInputLabel-root": {
        color: "grey",
    },
});

export default StyledTextField;
