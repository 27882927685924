import React, { createContext, useContext, useState } from "react";

const SnackBarContext = createContext();

export const SnackBarProvider = ({ children }) => {
    const [snackbarQueue, setSnackbarQueue] = useState([]);

    const pushSnackBar = ({ message, severity }) => {
        const key = new Date().getTime();
        setSnackbarQueue((prevQueue) => [...prevQueue, { message, severity, key }]);
        return key;
    };

    const closeSnackBar = (key) => {
        setSnackbarQueue((prevQueue) => prevQueue.filter((snack) => snack.key !== key));
    };

    const contextValue = { snackbarQueue, pushSnackBar, closeSnackBar };

    return <SnackBarContext.Provider value={contextValue}>{children}</SnackBarContext.Provider>;
};

export const useSnackBar = () => {
    const context = useContext(SnackBarContext);
    if (!context) {
        throw new Error("useSnackBar must be used within a SnackBarProvider");
    }
    return context;
};
