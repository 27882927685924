import React from "react";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import ColorButton from "../../../../styles/buttons/oauthButton";
import PropTypes from "prop-types";

const ReusableConnection = ({ serviceName, iconSrc, href, isVerified }) => {
    return (
        <Paper
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: 200,
                height: 120,
                padding: 16,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Avatar alt={`${serviceName} Avatar`} src={iconSrc} sx={{ width: 40, height: 40, borderRadius: 0 }} />

                <div
                    style={{
                        marginRight: 110,
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "12px",
                    }}
                >
                    {serviceName}
                </div>
            </div>

            <ColorButton
                variant="contained"
                fullWidth
                sx={{
                    width: "100%",
                    bottom: 6,
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "1.2rem",
                }}
                href={href}
                target="_self"
                rel="noopener noreferrer"
            >
                {isVerified ? "DISCONNECT" : "CONNECT"}
            </ColorButton>
        </Paper>
    );
};

ReusableConnection.propTypes = {
    serviceName: PropTypes.string.isRequired,
    iconSrc: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    isVerified: PropTypes.bool.isRequired,
};

export default ReusableConnection;
