import { styled, Dialog, Toolbar, List, ListItemIcon } from "@mui/material";

const CustomDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        margin: 0,
        overflow: "hidden",
    },
});

const TopBar = styled(Toolbar)({
    borderBottom: "1px solid",
});

const OptionsList = styled(List)({
    paddingTop: 0, // Adjust the marginTop as needed
});

const StyledListItemIcon = styled(ListItemIcon)({
    marginRight: -30, // Adjust the marginRight as needed
});

export { CustomDialog, TopBar, OptionsList, StyledListItemIcon };
