import { StyledContainer, StyledPaper } from "../../../styles/containers/authForm";

const FormContainer = ({ content }) => {
    return (
        <StyledContainer>
            <StyledPaper>{content}</StyledPaper>
        </StyledContainer>
    );
};

export default FormContainer;
