// Page.js

import "./styles.css";
import { TraktFunctions } from "./integrations";

export default function Page() {
    return (
        <div className="settings-container">
            <div className="trakt-connection">
                <TraktFunctions />
            </div>
        </div>
    );
}
