import React from "react";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html body {
        min-height: 100vh;
        min-height: -webkit-fill-available;
        background: linear-gradient(41deg, ${(props) => props.theme.palette.gradient.color1} 0%, ${(props) => props.theme.palette.gradient.color2} 100%);
        background-attachment: fixed;
        width: 100%;
        height: 100%;
        margin: 0;
    }
`;

const theme = createTheme({
    palette: {
        primary: {
            main: "#553fa8",
        },
        secondary: {
            main: "#9c27b0",
        },
        gradient: {
            color1: "#0c0b11",
            color2: "#1a173e",
        },
    },
});

const ThemeProvider = ({ children }) => (
    <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
        </StyledThemeProvider>
    </MuiThemeProvider>
);

export default ThemeProvider;
