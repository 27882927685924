import { useEffect } from "react";
import { useDiscoverContext } from "../../context/dashboardContext";
import Content from "./content";
import "./styles.css";


export default function Page() {
    const { userLists, userListData, setUserListData } = useDiscoverContext();

    useEffect(() => {
        const fetchData = async () => {

            if (userLists) {
                await Promise.all(
                    userLists.map(async (list) => {
                        if (!list.id) {
                            return;
                        }

                        const url = `${process.env.REACT_APP_API_URL}api/lists/${list.id}?limit=15&offset=0`;

                        try {
                            const response = await fetch(url, { timeout: 10000 });

                            if (response.ok) {
                                const result = await response.json();

                                // Ensure result is an array
                                const dataArray = Array.isArray(result) ? result : [];

                                setUserListData((prevData) => ({
                                    ...prevData,
                                    [list.id]: dataArray,
                                }));
                            }
                        } catch (error) {
                            console.error("Network error:", error);
                        }
                    }),
                );
            }
        };

        fetchData();
    }, [userLists]);

    return (
        <div className="home-container">
            {/* Render each Content component as the lists are fetched */}
            {userListData &&
                Object.entries(userListData).map(([id, data]) => {
                    const userList = userLists.find((list) => list.id === parseInt(id, 10));
                    const url = `${userList.type}/${userList.id}`;

                    return <Content key={id} data={data} label={`${userList.type} - ${userList.name}`} url={url} />;
                })}
        </div>
    );
}