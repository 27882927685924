import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import "./styles.css";

export default function CustomizedInputBase() {
    const Navigate = useNavigate();

    const handleSearchClick = () => {
        Navigate("/dashboard/search");
    };

    return (
        <Paper className="custom-searchbar" component="form" sx={{ p: "2px 4px", display: "flex" }}>
            <InputBase
                onClick={handleSearchClick}
                className="custom-searchbar-input"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search or paste link..."
                inputProps={{ "aria-label": "Search or paste link..." }}
            />

            <IconButton className="custom-searchbar-icon" type="button" sx={{ p: "5px" }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}
