

const fetchUserLists = async (cookies, setUserLists) => {

    try {
        const username = cookies.authUser || "";

        if (!username) {
            return;
        }

        const url = `${process.env.REACT_APP_API_URL}api/userlists/${username}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await response.json();

        if (Array.isArray(data) && data !== null) {
            data.sort((a, b) => {
                const typeA = a.type.toUpperCase(); // Ensure case-insensitive sorting
                const typeB = b.type.toUpperCase();

                if (typeA < typeB) {
                    return -1;
                }
                if (typeA > typeB) {
                    return 1;
                }
                return 0;
            });
        } 
        
        else {
            console.error("Invalid or missing userLists in localStorage");
        }

        // localStorage.setItem("userLists", JSON.stringify(data));
        setUserLists(data);
        
    
    } catch (error) {
        console.error("Error getting lists:", error);
    }
};

export default fetchUserLists;
