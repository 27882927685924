import DashboardSearchbar from "./search";
import { useRef, useEffect } from "react";

import { StremioIcon } from "../../icons";
import "./styles.css";

function Navbar() {
    const elementRef = useRef(null);

    useEffect(() => {
        if (elementRef.current) {
            const height = elementRef.current.clientHeight;
            document.documentElement.style.setProperty("--horizontal-height", `${height}px`);
        }
    }, []);

    return (
        <section ref={elementRef} className="nav-horizontal">
            <nav className="nav-horizontal navbar">
                <img className="logo" width="40" height="40" alt="logo" src={StremioIcon}></img>

                <div className="align-center">
                    <DashboardSearchbar />
                </div>
            </nav>
        </section>
    );
}

export default Navbar;
