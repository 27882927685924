import {useState} from "react";
import { styled } from "@mui/system";
import { Skeleton, DialogContent, IconButton, Box } from "@mui/material";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import CloseIcon from "@mui/icons-material/Close";
import { CustomDialog } from "../../../styles/frames/contentInformation";
import "./grid.css";

// Override the styling for the Skeleton component
const StyledSkeleton = styled(Skeleton)({
    "& > *:not(.MuiSvgIcon-root)": {
        visibility: "unset",
    },
});

const ImageGrid = ({ imageData }) => {
    const [loadedImages, setLoadedImages] = useState([]);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);

    const handleImageLoad = (index) => {
        setLoadedImages((prevLoadedImages) => {
            const updatedLoadedImages = [...prevLoadedImages];
            updatedLoadedImages[index] = true;
            return updatedLoadedImages;
        });
    };

    const handleImageError = (index) => {
        setLoadedImages((prevLoadedImages) => {
            const updatedLoadedImages = [...prevLoadedImages];
            updatedLoadedImages[index] = false;
            return updatedLoadedImages;
        });
    };

    const handleImageClick = (id, type) => {
        const selectedImageUrl = `https://web.stremio.com/#/detail/${type}/${id}`;
        setSelectedImageUrl(selectedImageUrl);
    };

    const handleCloseDialog = () => {
        setSelectedImageUrl(null);
    };

    return (
        <div className="grid-container">
            {imageData.map((item, index) => (
                <div key={index} className="grid-item" onClick={() => handleImageClick(item.id, item.type)}>
                    <StyledSkeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                        sx={{
                            borderRadius: "inherit",
                            objectFit: "cover",
                            boxSizing: "border-box",
                            display: loadedImages[index] === false || !loadedImages[index] ? "block" : "none",
                            animation: "none",
                            position: "relative",
                        }}
                    >
                        {/* Centered MovieFilterIcon */}
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "#86849a",
                                zIndex: 1,
                            }}
                        >
                            <MovieFilterIcon />
                        </Box>
                    </StyledSkeleton>

                    <img
                        src={item.imageUrl}
                        alt={item.title}
                        onLoad={() => handleImageLoad(index)}
                        onError={() => handleImageError(index)}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "inherit",
                            boxSizing: "border-box",
                            cursor: "pointer",
                            display: loadedImages[index] ? "block" : "none",
                        }}
                    />
                </div>
            ))}

            <CustomDialog
                open={!!selectedImageUrl}
                onClose={handleCloseDialog}
                style={{ width: "100%", height: "100%" }}
            >
                <DialogContent>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseDialog}
                        sx={{ position: "absolute", bottom: "8px", right: "28px", fontSize: "25px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {selectedImageUrl && (
                        <iframe
                            title="Embedded Website"
                            src={selectedImageUrl}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                        />
                    )}
                </DialogContent>
            </CustomDialog>
        </div>
    );
};

export default ImageGrid;
