import { Container, Typography, Button, Grid, Paper } from "@mui/material";
import { styled } from "@mui/system";

const CustomContainer = styled(Container)({
    marginTop: "20px",
    marginBottom: "2rem",
    // paddingLeft: 0,
    // paddingRight: 0,
    padding: "unset !important",
    marginLeft: 0,
    marginRight: 0,
    maxWidth: "unset !important",
});

const CustomGrid = styled(Grid)({
    display: "flex",
    gap: "5px", // possibly could make 30px to push an item off the screen when overflowing
    marginLeft: "30px",
    marginRight: "30px",
    marginBottom: "30px",
    marginTop: "13px",
    overflowX: "auto", // Enable horizontal scrolling for overflow
    maxWidth: "100%", // Ensure the grid takes the full width
});

const ImageContainer = styled("div")({
    flex: "0 0 auto", // Maintain the size of the item
});

const CustomPaper = styled(Paper)({
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    padding: "10px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.8)", // Adjust background color and opacity as needed
});

const Image = styled("img")({
    width: "100%",
    height: "95%",
    borderRadius: "10px",
    objectFit: "cover", // Maintain aspect ratio while covering the container
    boxSizing: "border-box", // Include the border in the box model
    border: "3px solid transparent", // Set a transparent border initially
    transition: "border 0.3s ease-in-out", // Add a smooth transition effect
    "&:hover": {
        border: "3px solid #7c5cf5", // Add your desired border style and color
    },
    cursor: "pointer",

    "@media (max-width: 503px)": {
        maxWidth: "120px",
    },

    "@media (min-width: 504px)": {
        maxWidth: "146px",
    },

    "@media (min-width: 1059px) and (min-height: 700px)": {
        maxWidth: "162px",
    },

    "@media (min-width: 1059px) and (min-height: 734px)": {
        maxWidth: "177px",
    },

    "@media (min-width: 1059px) and (min-height: 780px)": {
        maxWidth: "193px",
    },

    "@media (min-width: 1059px) and (min-height: 825px)": {
        maxWidth: "208px",
    },

    "@media (min-width: 1059px) and (min-height: 875px)": {
        maxWidth: "225px",
    },
});

const CustomTypography = styled(Typography)({
    marginLeft: "28px",
    color: "white", // Set the desired font color
    fontSize: "18px",
});

const CustomButton = styled(Button)({
    marginRight: "30px",
    fontSize: "0.6rem", // Set your desired font size
    maxWidth: "90px",
});

export { CustomContainer, CustomButton, CustomGrid, CustomPaper, CustomTypography, Image, ImageContainer };
