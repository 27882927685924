import React, { useRef, useState, useEffect } from "react";
import {
    StyledFormControl,
    StyledOutlinedInput,
    StyledSelect,
    StyledMenuItem,
} from "../../../styles/dropdowns/catalogSelection";

const SelectionDropdown = ({ options, labelText, onSelect, selectedValue }) => {
    const [localSelectedValue, setLocalSelectedValue] = useState("");
    const [menuWidth, setMenuWidth] = useState(null);
    const selectRef = useRef(null);

    useEffect(() => {
        if (selectedValue !== undefined && selectedValue !== localSelectedValue) {
            setLocalSelectedValue(selectedValue);
        }
    }, [selectedValue, localSelectedValue]);

    useEffect(() => {
        const handleResize = () => {
            if (selectRef.current) {
                const parentWidth = selectRef.current.getBoundingClientRect().width;
                setMenuWidth(parentWidth);
            }
        };

        handleResize(); // Initial calculation
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleChange = (event) => {
        const selectedOption = event.target.value;
        setLocalSelectedValue(selectedOption);
        onSelect(selectedOption);
    };

    return (
        <StyledFormControl style={{ width: "100%" }}>
            <StyledSelect
                displayEmpty
                value={localSelectedValue}
                onChange={handleChange}
                input={<StyledOutlinedInput />}
                inputProps={{ "aria-label": "Without label" }}
                ref={selectRef}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200,
                            width: menuWidth || undefined, // Set it to match parent width
                        },
                    },
                }}
            >
                {options.length > 0 ? (
                    options.map((option) => (
                        <StyledMenuItem key={option.value} value={option.value} style={{ width: "80%" }}>
                            {option.label}
                        </StyledMenuItem>
                    ))
                ) : (
                    <StyledMenuItem disabled value="">
                        {labelText}
                    </StyledMenuItem>
                )}
            </StyledSelect>
        </StyledFormControl>
    );
};

SelectionDropdown.defaultProps = {
    options: [],
    labelText: "Default Option",
    selectedValue: "",
    onSelect: () => {},
};

export default SelectionDropdown;
