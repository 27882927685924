import StyledTextField from "../../../styles/input/authTextField";

const AuthPasswordField = ({ isRegister, onChange, confirmValue, onConfirmChange }) => (
    <>
        <StyledTextField label="Password" type="password" variant="outlined" color="secondary" onChange={onChange} />
        {isRegister && (
            <StyledTextField
                label="Confirm Password"
                type="password"
                variant="outlined"
                color="secondary"
                value={confirmValue}
                onChange={onConfirmChange}
            />
        )}
    </>
);

const AuthUsernameField = ({ onChange }) => (
    <StyledTextField label="Username" variant="outlined" color="secondary" onChange={onChange} />
);

export { AuthPasswordField, AuthUsernameField };
