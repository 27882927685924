import React, { createContext, useContext, useState } from "react";

const DiscoverContext = createContext();

export const DiscoverProvider = ({ children }) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");

    const [filteredLists, setFilteredLists] = useState([]);
    const [selectedList, setSelectedList] = useState("");

    const [genres, setGenres] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState("");
    const [selectedGenres, setSelectedGenres] = useState([]);

    const [userLists, setUserLists] = useState([]);
    const [userListData, setUserListData] = useState({});

    return (
        <DiscoverContext.Provider
            value={{
                userLists,
                setUserLists,

                userListData, 
                setUserListData,

                categories,
                setCategories,
                selectedCategory,
                setSelectedCategory,

                filteredLists,
                setFilteredLists,
                selectedList,
                setSelectedList,
                
                genres,
                setGenres,
                selectedGenre,
                setSelectedGenre,

                selectedGenres,
                setSelectedGenres,
            }}
        >
            {children}
        </DiscoverContext.Provider>
    );
};

export const useDiscoverContext = () => {
    return useContext(DiscoverContext);
};
