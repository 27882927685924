import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useDiscoverContext } from "../../../context/dashboardContext";

const StyledSelect = styled(Select)({
    backgroundColor: "rgb(15, 13, 32)",
    "& .MuiSelect-icon": {
        color: "rgb(154, 152, 166)",
    },
});

const StyledInputLabel = styled(InputLabel)({
    color: "rgb(154, 152, 166)",
});

const StyledMenuItem = styled(MenuItem)({
    color: "rgb(154, 152, 166)",
});

const StyledFormControl = styled(FormControl)({
    marginTop: 2,
});

const SelectRatings = ({ onRatingChange }) => {
    const { selectedList, userLists } = useDiscoverContext();
    const ratingsArray = Array.from({ length: 10 }, (_, index) => index + 1);
    const [selectedRating, setSelectedRating] = useState("");

    useEffect(() => {
        try {
            if (!Array.isArray(userLists)) {
                console.error("Invalid userLists format:", userLists);
                return;
            }

            const matchingList = userLists.find((list) => {
                return Number(selectedList) === list.id;
            });

            if (matchingList && matchingList.filters) {
                const { rating } = matchingList.filters;

                if (rating && rating !== selectedRating) {
                    setSelectedRating(rating);
                    onRatingChange(rating);
                }

                else {
                    setSelectedRating("");
                    onRatingChange("");
                }
            }

        } catch (error) {
            console.error("Error parsing userLists from localStorage:", error);
        }
        
    }, [userLists, selectedList]);

    const handleRatingChange = (rating) => {
        setSelectedRating(rating);
        onRatingChange(rating); // Call the callback with the selected rating
    };

    return (
        <StyledFormControl fullWidth sx={{ marginTop: 3, marginBottom: 2 }}>
            <StyledInputLabel>Exclude Rating</StyledInputLabel>
            <StyledSelect
                data-testid=""
                fullWidth
                label="Exclude Rating"
                value={selectedRating}
                onChange={(e) => handleRatingChange(e.target.value)}
                renderValue={(selected) => (
                    <>
                        <StyledMenuItem>{selected}</StyledMenuItem>
                    </>
                )}
                MenuProps={{
                    PaperProps: { style: { maxHeight: 105 } },
                }}
            >
                {ratingsArray.map((rating) => (
                    <StyledMenuItem key={rating} value={rating}>
                        {rating}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
        </StyledFormControl>
    );
};

export default SelectRatings;
