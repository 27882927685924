import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    SearchContainer,
    SettingsContainer,
    LibraryContainer,
    DiscoverContainer,
    HomeContainer,
} from "../../../containers";


function DashboardRoutes() {
    return (
        <Routes>
            <Route path="/dashboard" element={<HomeContainer />} />
            <Route path="/dashboard/search" element={<SearchContainer />} />
            <Route path="/dashboard/discover" element={<DiscoverContainer />} />
            <Route path="/dashboard/discover/:category/:listID" element={<DiscoverContainer />} />
            <Route path="/dashboard/library" element={<LibraryContainer />} />
            <Route path="/dashboard/settings" element={<SettingsContainer />} />
        </Routes>
    );
}

export default DashboardRoutes;
