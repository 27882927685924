import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSnackBar } from "./snackbarContext";
import React from "react";
import fetchUserLists from "./listsStorage";

const useAuthFunctions = () => {
    const { pushSnackBar, closeSnackBar } = useSnackBar();
    const location = useLocation();
    const isRegisterRoute = location.pathname === "/register";
    const [cookies, setCookie] = useCookies();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);

    const validateInputData = (username, password, confirmPassword) => {
        const usernameRegex = /^[a-zA-Z0-9]{4,}$/;
        const isUsernameValid = usernameRegex.test(username);

        const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*-_])[a-zA-Z0-9!@#$%^&*-_]{8,}$/;
        const isPasswordValid = passwordRegex.test(password);

        if (isRegisterRoute && (username.trim() === "" || password.trim() === "" || confirmPassword.trim() === "")) {
            pushSnackBar({
                message: "Please fill in all required fields before proceeding.",
                severity: "warning",
            });
            return false;
        }

        if (isRegisterRoute && password !== confirmPassword) {
            pushSnackBar({
                message:
                    "The entered passwords do not match. Please ensure both password fields contain the same values.",
                severity: "warning",
            });
            return false;
        }

        if (!isRegisterRoute && (username.trim() === "" || password.trim() === "")) {
            pushSnackBar({
                message: "Please fill in all required fields before proceeding.",
                severity: "warning",
            });
            return false;
        }

        if (isRegisterRoute && !isUsernameValid) {
            pushSnackBar({
                message:
                    "Invalid username format. Usernames can only contain alphanumeric characters and must be 4 characters or more in length. Please avoid spaces and special characters.",
                severity: "warning",
            });
            return false;
        }

        if (isRegisterRoute && !isPasswordValid) {
            pushSnackBar({
                message:
                    "Invalid password format. Passwords must be at least 8 characters long and contain special characters and numbers. Ensure your password meets these requirements.",
                severity: "warning",
            });
            return false;
        }

        return true;
    };

    const handleValidation = async (username, password, confirmPassword) => {
        setLoading(true);
        const isValidated = validateInputData(username, password, confirmPassword);

        if (isValidated) {
            await handleSubmit(username, password, confirmPassword);
        }

        setLoading(false);
    };

    const handleSubmit = async (username, password, confirmPassword) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const endpoint = isRegisterRoute ? "api/register" : "api/login";
        const url = `${apiUrl}${endpoint}`;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username,
                    password,
                }),
            });

            if (response.ok) {
                const userData = await response.json();
                const sixHoursInSeconds = 6 * 60 * 60;

                setCookie("authUser", userData.username, {
                    maxAge: sixHoursInSeconds,
                    path: "/",
                });
                setCookie("authToken", userData.uuid, {
                    maxAge: sixHoursInSeconds,
                    path: "/",
                });

                if (isRegisterRoute) {
                    pushSnackBar({
                        message: "Registration successful! You can now log in with your credentials.",
                        severity: "success",
                    });
                    navigate("/dashboard");
                } else {
                    const key = pushSnackBar({
                        message: "Login successful! Welcome back.",
                        severity: "success",
                    });

                    setTimeout(() => {
                        closeSnackBar(key);
                    }, 900);

                    navigate("/dashboard");
                }
            } else {
                if (!isRegisterRoute) {
                    pushSnackBar({
                        message: "Login failed. Please check your username and password and try again.",
                        severity: "error",
                    });
                } else {
                    pushSnackBar({
                        message:
                            "Registration failed. Please ensure your username is alphanumeric, without special characters, and meets length requirements.",
                        severity: "error",
                    });
                }
            }
        } catch (error) {
            console.log(error);
            pushSnackBar({
                message: "Authentication error. Please try again later or contact support for assistance.",
                severity: "error",
            });
        }
    };

    return { handleValidation, loading };
};

const verifyUser = async (cookies, removeCookie, setIsAuthed, setLoading, setUserLists) => {
    try {
        const authToken = cookies.authToken;
        const authUser = cookies.authUser;

        if (authToken && authUser) {
            const apiUrl = process.env.REACT_APP_API_URL;
            const endpoint = "api/verify";
            const url = `${apiUrl}${endpoint}`;

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username: authUser, uuid: authToken }),
            });

            if (response.ok) {
                await fetchUserLists(cookies, setUserLists)
                setIsAuthed(true);
            }



            // else {
            //     setIsAuthed(false);
            //     removeCookie('authToken', {path: '/'});
            //     removeCookie('authUser', {path: '/'});
            // }
        } else {
            setIsAuthed(false);
        }
    } catch (error) {
        setIsAuthed(false);
    } finally {
        setLoading(false);
    }
};

const destroySession = async (cookies) => {
    const authToken = cookies.authToken;
    const authUser = cookies.authUser;

    const apiUrl = process.env.REACT_APP_API_URL;
    const endpoint = "api/destroy";
    const url = `${apiUrl}${endpoint}`;

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: authUser,
                uuid: authToken,
            }),
        });

        if (response.ok) {
            console.log("Logout successful");
        } else {
            console.log("Failed to destroy session");
        }
    } catch (error) {
        console.error("Error during logout:", error);
    }
};

export { useAuthFunctions, verifyUser, destroySession };
