// AuthComponent.js
import React, { useState } from "react";
import { useLocation, Navigate } from "react-router-dom"; // Add this import

import Navbar from "../../common/auth/navbar";
import AuthFormContainer from "../../common/auth/container";
import { AuthUsernameField, AuthPasswordField } from "../../common/auth/textField";
import { AuthFormButton } from "../../common/auth/buttons";
import { useAuthFunctions } from "../../utils/authFunctions";
import Dialog from "../../Dialog";

const AuthComponent = () => {
    const { handleValidation, loading } = useAuthFunctions();
    const location = useLocation(); // Add this line
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    if (location.pathname.startsWith("/dashboard")) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <Navbar />
            <AuthFormContainer
                content={
                    <>
                        <AuthUsernameField onChange={(e) => setUsername(e.target.value)} />
                        <AuthPasswordField
                            isRegister={location.pathname === "/register"}
                            onChange={(e) => setPassword(e.target.value)}
                            confirmValue={confirmPassword}
                            onConfirmChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <AuthFormButton
                            onClick={() => handleValidation(username, password, confirmPassword)}
                            loading={loading}
                        />
                    </>
                }
            />
            <Dialog />
        </>
    );
};

export default AuthComponent;
