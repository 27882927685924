import React from "react";
import { CircularProgress } from "@mui/material";

const LoadingOverlay = () => {
    const overlayStyles = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "linear-gradient(41deg, #0c0b11 0%, #1a173e 100%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999, // Set a high z-index to make sure it's on top
    };

    return (
        <div style={overlayStyles}>
            <CircularProgress color="secondary" />
        </div>
    );
};

export default LoadingOverlay;
