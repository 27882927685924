import { DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SelectRatings from "../../../common/edit/select/Ratings";
import SelectGenres from "../../../common/edit/select/Genres";
import StyledTextField from "../../../styles/input/editTextField";
import BootstrapDialog from "../../../styles/dialogs/editDialog";
import { useDiscoverContext } from "../../../context/dashboardContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

const CustomizedDialogs = ({ open, onClose }) => {
    const { selectedList, selectedCategory, setSelectedCategory, userLists, setUserLists } = useDiscoverContext();
    const [listName, setListName] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [selectedRating, setSelectedRating] = useState("");
    const [cookies] = useCookies(["authUser"]);

    useEffect(() => {
        setCategoryName(selectedCategory);
    }, [selectedCategory]);

    useEffect(() => {
        const foundList = userLists.find((list) => list.id === Number(selectedList));
        if (foundList) setListName(foundList.name);
    }, [selectedList, userLists]);

    const handleCategoryChange = (event) => {
        setCategoryName(event.target.value);
    };

    const handleListChange = (event) => {
        setListName(event.target.value);
    };

    const handleGenresChange = (genres) => {
        setSelectedGenres(genres);
    };

    const handleRatingChange = (rating) => {
        setSelectedRating(rating);
    };


    const handleSave = async () => {
        
        const selectedListName = userLists.find((list) => list.id === selectedList);

        const data = {
            rename: listName !== selectedListName 
                        ? listName 
                        : selectedListName,

            type: categoryName !== selectedCategory 
                        ? categoryName 
                        : selectedCategory,

            filters: {
                rating: selectedRating || 0,
                genres: selectedGenres,
            }
        };

        await updateUserList(selectedList, data);

        const newData = userLists.map((obj) => {
            if (obj.id === Number(selectedList)) {
                
                const newObj = {...obj};
                newObj.name = data.rename;
                newObj.type = data.type;
                newObj.filters = data.filters;

                return newObj;
            }

            return obj;
        });

        window.history.pushState(null, "", `/dashboard/discover/${categoryName}/${selectedList}`);
        await setSelectedCategory(categoryName);
        await setUserLists(newData);
        onClose(false);
    };


    const handleClose = () => {
        onClose(false);
    };


    const updateUserList = async (listId, updateData) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updateData),
        };

        try {
            const response = await fetch(`https://stormy-anchorage-69790-c84168c7a328.herokuapp.com/trakt/edit/${cookies.authUser}/list/${listId}`, requestOptions);
            const data = await response.json();

            if (response.ok) {
                console.log("List updated successfully:", data.updatedList);
            } 
            
            else {
                console.error("Error updating list:", data.error);
            }
        } 
        
        catch (error) {
            console.error("Error:", error);
        }
    };


    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle
                sx={{ m: 0, p: 2, color: (theme) => theme.palette.grey[500] }}
                id="customized-dialog-title"
                style={{ marginLeft: "20px" }}
            >
                Edit List
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <StyledTextField
                    fullWidth
                    margin="normal"
                    label="Rename Category"
                    value={categoryName}
                    onChange={handleCategoryChange}
                />
                <StyledTextField
                    fullWidth
                    margin="normal"
                    label="Rename List"
                    value={listName}
                    onChange={handleListChange}
                />
                <SelectGenres onGenresChange={handleGenresChange} />
                <SelectRatings onRatingChange={handleRatingChange} />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
                <Button variant="contained" color="primary" sx={{ width: "300px" }} autoFocus onClick={handleSave}>
                    Save
                </Button>
                <Button variant="contained" color="primary" sx={{ width: "300px" }} onClick={handleClose}>
                    Delete
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
};

export default CustomizedDialogs;
