import { styled, Container } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const SelectionContainer = () => {
    const isTablet = useMediaQuery("(max-width:640px)");
    const StyledContainer = styled(Container)({
        position: "fixed",
        top: "var(--horizontal-height)",
        left: "var(--vertical-width)",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "flex",
        gap: "10px",
        justifyContent: isTablet ? "space-between" : "flex-start", // Space between on xs, flex-start on other sizes
        width: isTablet ? "100%" : "80%", // Set width to 100% on xs, 80% on other sizes
    });

    return StyledContainer;
};

export default SelectionContainer;
