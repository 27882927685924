import { FormControl, Select, MenuItem, OutlinedInput, styled } from "@mui/material";

const StyledFormControl = styled(FormControl)({
    margin: 1,

    "@media (max-width: 970px)": {
        width: "100%", // Adjust the width for screens smaller than 600px
    },
});

const StyledOutlinedInput = styled(OutlinedInput)({
    color: "rgb(154, 152, 166)",
});

const StyledSelect = styled(Select)({
    "&.MuiInputBase-root": {
        backgroundColor: "rgb(15, 13, 32)",
        height: "43px",
    },

    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7c5cf5",
    },

    "&:focus .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7c5cf5",
    },
});

const StyledMenuItem = styled(MenuItem)({
    backgroundColor: "rgb(15, 13, 32)",
    overflow: "hidden",

    "&.Mui-selected": {
        backgroundColor: "rgb(15, 13, 32)",
    },

    "&.Mui-selected:hover": {
        backgroundColor: "rgb(15, 13, 32)",
    },
});

export { StyledFormControl, StyledOutlinedInput, StyledSelect, StyledMenuItem };
