// discoverContainer.jsx

import { useState } from "react";
import Catalogs from "./catalogs";
import Content from "./content";
import "./styles.css";

import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import { useMediaQuery } from "@mui/material";
import DialogComponent from "./catalogs/dialogs";

// import { DiscoverProvider } from "../../context/discoverContext";

export default function Page() {
    const [selectedList, setSelectedList] = useState("");
    const [selectedGenre, setSelectedGenre] = useState("");
    const isWideScreen = useMediaQuery("(min-width:985px)");
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleDialogToggle = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    const handleListChange = (newList) => {
        setSelectedList(newList);
    };

    const handleGenreChange = (newGenre) => {
        setSelectedGenre(newGenre);
    };

    return (
        // <DiscoverProvider>
            <div className="discover-container">
                <Catalogs onListChange={handleListChange} onGenreChange={handleGenreChange} />
                <Content selectedList={selectedList} selectedGenre={selectedGenre} />

                {!isWideScreen && (
                    // Floating EditIcon when !isWideScreen
                    <div
                        style={{ position: "absolute", bottom: "16px", right: "16px", zIndex: 1000 }}
                        onClick={handleDialogToggle}
                    >
                        <Fab color="primary" aria-label="edit">
                            <EditIcon />
                        </Fab>
                    </div>
                )}

                <DialogComponent open={isDialogOpen} onClose={handleDialogToggle} />
            </div>
        // </DiscoverProvider>
    );
}
