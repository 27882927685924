// ConsecutiveSnackbar.js
import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSnackBar } from "./snackbarContext";

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const ConsecutiveSnackbar = () => {
    const { snackbarQueue, closeSnackBar } = useSnackBar();

    useEffect(() => {
        if (snackbarQueue.length > 0) {
            const keys = snackbarQueue.map(({ key }) => key);

            const keySet = new Set(keys);
            if (keySet.size !== keys.length) {
                console.error("Duplicate keys found in SnackbarQueue:", keys);
            }

            const { key } = snackbarQueue[0];
            const timeoutId = setTimeout(() => {
                closeSnackBar(key);
            }, 6000);

            // Cleanup function to clear the timeout when component unmounts or when a new snackbar is added
            return () => clearTimeout(timeoutId);
        }
    }, [snackbarQueue, closeSnackBar]);

    return (
        <div>
            {snackbarQueue.map(({ message, severity, key }) => (
                <Snackbar
                    key={key}
                    open
                    autoHideDuration={2000}
                    onClose={() => closeSnackBar(key)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert
                        onClose={() => closeSnackBar(key)}
                        severity={severity}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            whiteSpace: "pre-line",
                        }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            ))}
        </div>
    );
};

export default ConsecutiveSnackbar;
