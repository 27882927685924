import { Paper, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
});

const StyledPaper = styled(Paper)({
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: "rgba(15, 13, 32, 0.9) !important", // Slightly transparent white background
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", // 3D paper effect
});

export { StyledContainer, StyledPaper };
