import { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AddonIcon, DiscoverIcon, HomeIcon, LibraryIcon, SettingsIcon } from "../../icons";
import { useMediaQuery } from "@mui/material";

import DashboardProfileDropdown from "./dropdown";
import "./styles.css";

const Sidebar = () => {
    const elementRef = useRef(null);
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        if (elementRef.current) {
            const width = elementRef.current.clientWidth;
            document.documentElement.style.setProperty("--vertical-width", `${width}px`);
            document.documentElement.style.setProperty("--bottom-navbar-height", `0px`);
        }
    });

    return (
        <section className="nav-vertical" ref={elementRef}>
            <div className="top-icons">
                <div className="icon">
                    <Link
                        to="dashboard"
                        className={activeLink.includes("/dashboard/discover") ? "nav-link active" : "nav-link"}
                    >
                        <HomeIcon />
                    </Link>
                </div>
                <div className="icon">
                    <Link
                        to="dashboard/discover"
                        className={activeLink.includes("/dashboard/discover") ? "nav-link active" : "nav-link"}
                    >
                        <DiscoverIcon />
                    </Link>
                </div>
                <div className="icon">
                    <Link
                        to="dashboard/library"
                        className={activeLink === "/dashboard/library" ? "nav-link active" : "nav-link"}
                    >
                        <LibraryIcon />
                    </Link>
                </div>
                <div className="icon">
                    <Link
                        to="dashboard/addons"
                        className={activeLink === "/dashboard/addons" ? "nav-link active" : "nav-link"}
                    >
                        <AddonIcon />
                    </Link>
                </div>
                <div className="icon">
                    <Link
                        to="dashboard/settings"
                        className={activeLink === "/dashboard/settings" ? "nav-link active" : "nav-link"}
                    >
                        <SettingsIcon />
                    </Link>
                </div>
            </div>

            {useMediaQuery("(min-height: 503px)") ? <DashboardProfileDropdown /> : null}
        </section>
    );
};

export default Sidebar;
