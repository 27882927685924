import SvgIcon from "@mui/material/SvgIcon";

const DiscoverIcon = () => {
    return (
        <SvgIcon>
            <svg className="discover" viewBox="0 0 512 512">
                <path d="M448 256c0-106-86-192-192-192s-192 86-192 192 86 192 192 192 192-86 192-192Z" />

                <path d="M350.70000000000005 150.9000000000001l-117.2 46.9a63.001 63.001 0 0 0-21.5 14.2 66.036 66.036 0 0 0-14.2 21.5l-46.9 117.2a7.95 7.95 0 0 0-0.4 4.6 8.737 8.737 0 0 0 2.2 4.1 7.82 7.82 0 0 0 4.1 2.2c1.54 0.32 3.14 0.18 4.6-0.4l117.2-46.9c8.07-3.2 15.39-8.04 21.5-14.2a66.141 66.141 0 0 0 14.2-21.5l46.9-117.2c0.59-1.46 0.73-3.06 0.4-4.6a8.737 8.737 0 0 0-2.2-4.1 8.173 8.173 0 0 0-4.08-2.2 8.103 8.103 0 0 0-4.62 0.4Zm-94.7 129.1c-4.73 0.01-9.36-1.38-13.3-4a23.28 23.28 0 0 1-8.8-10.8c-1.81-4.4-2.29-9.23-1.4-13.9 0.91-4.67 3.21-8.96 6.6-12.3a23.996 23.996 0 0 1 33.95-0.05 23.999 23.999 0 0 1 0.05 33.95 24.321 24.321 0 0 1-17.1 7.1Z" />
            </svg>
        </SvgIcon>
    );
};

export default DiscoverIcon;
