// content.jsx

import React, { useState, useEffect, useRef } from "react";
import Grid from "./grid";
import LoadingOverlay from "./loading";
import "./content.css";

const ImageContent = ({ selectedList, selectedGenre }) => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [limit] = useState(100);
    const [filteredData, setFilteredData] = useState([]);

    const handleScroll = async () => {
        const container = containerRef.current;
        if (container.scrollHeight - container.scrollTop === container.clientHeight) {
            await fetchMoreData(selectedList, offset, limit);
        }
    };

    const fetchMoreData = async (listId, offset, limit) => {
        try {
            if (!listId) {
                return;
            }

            const url = `${process.env.REACT_APP_API_URL}api/lists/${listId}?limit=${limit}&offset=${offset}`;

            const response = await fetch(url);

            if (response.ok) {
                const newData = await response.json();
                const uniqueNewData = newData.filter((newItem) => !apiData.some((item) => item.id === newItem.id));
                setApiData((prevData) => [...prevData, ...uniqueNewData]);
                setOffset((prevOffset) => prevOffset + limit);
            }
        } catch (error) {
            console.error("Error fetching more data:", error);
        }
    };

    useEffect(() => {
        setApiData([]);
        setOffset(0);
        fetchData(selectedList);
    }, [selectedList]);

    const containerRef = useRef();

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener("scroll", handleScroll);

        return () => {
            container.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        const filteredResult = apiData.filter((item) => {
            if (selectedGenre === "None") {
                return true;
            }

            return item.genres.includes(selectedGenre.toLowerCase());
        });

        setFilteredData(filteredResult);
    }, [apiData, selectedGenre]);

    const fetchData = async (listId) => {
        try {
            setLoading(true);

            if (!listId) {
                setLoading(false);
                return;
            }

            const url = `${process.env.REACT_APP_API_URL}api/lists/${listId}?limit=${limit}&offset=${offset}`;

            const response = await fetch(url);

            if (response.ok) {
                const data = await response.json();
                setApiData(data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const imageData = filteredData.map((item) => ({
        id: item.id,
        title: item.name,
        imageUrl: item.poster,
        type: item.type,
    }));

    return (
        <div className="items-container" ref={containerRef}>
            {loading ? <LoadingOverlay /> : <Grid imageData={imageData} />}
        </div>
    );
};

export default ImageContent;
