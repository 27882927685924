import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(15, 13, 32, 0.3)",
    fontFamily: "Montserrat, sans-serif",
    "&:hover": {
        backgroundColor: "rgba(15, 13, 32, 0.1)",
    },
}));

export default ColorButton;
