import SvgIcon from "@mui/material/SvgIcon";

const LibraryIcon = () => {
    return (
        <SvgIcon>
            <svg className="library" viewBox="0 0 512 512">
                <path d="M419.0999999999999 176h-326.3a28.9 28.9 0 0 0-20.43 8.47 28.9 28.9 0 0 0-8.47 20.43V403.20000000000005a28.9 28.9 0 0 0 8.47 20.43 28.9 28.9 0 0 0 20.43 8.47h326.3a28.9 28.9 0 0 0 20.43-8.47A28.9 28.9 0 0 0 448 403.20000000000005v-198.3c0.01-3.8-0.74-7.56-2.19-11.07a28.953 28.953 0 0 0-6.26-9.38 28.953 28.953 0 0 0-9.38-6.26c-3.51-1.45-7.27-2.2-11.07-2.19Z" />
                <path d="M144 80h224" />
                <path d="M112 128h288" />
            </svg>
        </SvgIcon>
    );
};

export default LibraryIcon;
