import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgb(23, 21, 56)",
    fontFamily: "Montserrat, sans-serif",
    position: "relative",
    "&:hover": {
        backgroundColor: "rgba(23, 21, 56, 0.3)",
    },
    marginTop: "16px",
}));

export default ColorButton;
