import { styled, Dialog } from "@mui/material";

const CustomDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        width: "85%",
        height: "85%",
        maxWidth: "100%",
        maxHeight: "100%",
        margin: 0,
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 0) !important", // Adjust the alpha (opacity) value as needed
    },

    "& .MuiDialogContent-root": {
        overflowY: "unset !important",
        padding: "unset !important",
    },
});

export { CustomDialog };
