import { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AddonIcon, DiscoverIcon, HomeIcon, LibraryIcon, SettingsIcon } from "../../icons";
import "./styles.css";

export default function Footer() {
    const elementRef = useRef(null);
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        if (elementRef.current) {
            const height = elementRef.current.clientHeight;
            document.documentElement.style.setProperty("--bottom-navbar-height", `${height}px`);
            document.documentElement.style.setProperty("--vertical-width", "0px");
        }
    });

    return (
        <div ref={elementRef} className="footer">
            <div className="icon">
                <Link to="dashboard" className={activeLink === "/dashboard" ? "nav-link active" : "nav-link"}>
                    <HomeIcon />
                </Link>
            </div>
            <div className="icon">
                <Link
                    to="dashboard/discover"
                    className={activeLink === "/dashboard/discover" ? "nav-link active" : "nav-link"}
                >
                    <DiscoverIcon />
                </Link>
            </div>
            <div className="icon">
                <Link
                    to="dashboard/library"
                    className={activeLink === "/dashboard/library" ? "nav-link active" : "nav-link"}
                >
                    <LibraryIcon />
                </Link>
            </div>
            <div className="icon">
                <Link
                    to="dashboard/addons"
                    className={activeLink === "/dashboard/addons" ? "nav-link active" : "nav-link"}
                >
                    <AddonIcon />
                </Link>
            </div>
            <div className="icon">
                <Link
                    to="dashboard/settings"
                    className={activeLink === "/dashboard/settings" ? "nav-link active" : "nav-link"}
                >
                    <SettingsIcon />
                </Link>
            </div>
        </div>
    );
}
