import "./styles.css";
import { AuthNavButton } from "../buttons";
import { StremioIcon } from "../../icons";
import LogoDraft from "./inner-shadow-flat.png";

const Navbar = () => {
    return (
        <div className="navbar">
            <div className="left">
                <img src={StremioIcon} className="icon" />
                <img src={LogoDraft} alt="Text as Image" className="text-image" />
            </div>

            <div className="right">
                <AuthNavButton />
            </div>
        </div>
    );
};

export default Navbar;
