import { Link, useLocation } from "react-router-dom";
import React from "react";
import ColorButton from "../../../styles/buttons/authNav";

export default function () {
    const location = useLocation();

    // Determine if it's a login page based on location.pathname
    const isLoginPage = location.pathname === "/login" || location.pathname === "/";
    const isRegisterPage = location.pathname === "/register";

    const routePath = isLoginPage ? "/register" : isRegisterPage ? "/login" : "/login";

    const buttonText = isLoginPage ? "Register" : isRegisterPage ? "Login" : "Login";

    return (
        <Link to={routePath} style={{ textDecoration: "none" }}>
            <ColorButton variant="contained">{buttonText}</ColorButton>
        </Link>
    );
}
