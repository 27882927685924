import { styled } from "@mui/system";
import { Dialog } from "@mui/material";

const BootstrapDialog = styled(Dialog)({
    "& .MuiDialogContent-root": {
        padding: (theme) => theme.spacing(2),
        background: "linear-gradient(41deg, #0c0b11 0%, #1a173e 100%)",
    },
    "& .MuiDialogActions-root": {
        padding: (theme) => theme.spacing(1),
    },
});

export default BootstrapDialog;
