import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { StyledButton, ButtonContainer, CircularProgressContainer } from "../../../styles/buttons/authForm";

const FormButton = ({ onClick, loading }) => {
    const location = useLocation();
    const isLoginPage = location.pathname === "/login" || location.pathname === "/";
    const isRegisterPage = location.pathname === "/register";

    // Use local state for re-rendering
    const [isLoading, setIsLoading] = useState(loading);

    // Update local state when the loading prop changes
    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    const buttonText = isLoginPage ? "Login" : isRegisterPage ? "Register" : "Login";

    return (
        <StyledButton variant="contained" color="secondary" onClick={onClick}>
            <ButtonContainer>
                {buttonText}
                {isLoading && <CircularProgressContainer color="secondary" size={24} />}
            </ButtonContainer>
        </StyledButton>
    );
};

export default FormButton;
