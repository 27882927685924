import { Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)({
    minWidth: "240px",
    backgroundColor: "rgb(23, 21, 56)",
    fontFamily: "Montserrat, sans-serif !important",
    position: "relative",
    "&:hover": {
        backgroundColor: "rgba(23, 21, 56, 0.3)",
    },
    marginTop: "16px",
});

const ButtonContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
});

const CircularProgressContainer = styled(CircularProgress)({
    position: "absolute",
    right: -80,
    marginLeft: "auto",
});

export { StyledButton, ButtonContainer, CircularProgressContainer };
