import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ThemeProvider from "./utils/themeProvider";
import { DiscoverProvider } from "./context/dashboardContext";

console.warn = () => {};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <DiscoverProvider>
                <App />
            </DiscoverProvider>
        </ThemeProvider>
    </React.StrictMode>,
);
