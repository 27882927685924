import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/system";
import { useState, useEffect } from "react";
import { useDiscoverContext } from "../../../context/dashboardContext";

const StyledSelect = styled(Select)({
    backgroundColor: "rgb(15, 13, 32)",
    "& .MuiSelect-icon": {
        color: "rgb(154, 152, 166)",
    },
});

const StyledInputLabel = styled(InputLabel)({
    color: "rgb(154, 152, 166)",
});

const StyledMenuItem = styled(MenuItem)({
    color: "rgb(154, 152, 166)",
});

const StyledFormControl = styled(FormControl)({
    marginTop: 2,
});

const SelectGenres = ({ onGenresChange }) => {
    const { genres, selectedList, userLists } = useDiscoverContext();
    const genresWithoutNone = genres.slice(1);

    const [selectedGenres, setSelectedGenres] = useState([]);

    useEffect(() => {
        try {
            if (!Array.isArray(userLists)) {
                console.error("Invalid userLists format:", userLists);
                return;
            }

            const matchingList = userLists.find((list) => {
                return Number(selectedList) === list.id;
            });

            if (matchingList && matchingList.filters) {
                const { genres } = matchingList.filters;
                
                if (genres && genres.length > 0) {
                    setSelectedGenres([...genres]);
                    onGenresChange([...genres]);

                } else {
                    setSelectedGenres([]);
                    onGenresChange([]);
                }
            } 
            
            else {
                setSelectedGenres([]);
                onGenresChange([]);
            }
        } 
        
        catch (error) {
            console.error("Error parsing userLists from localStorage:", error);
        }
    }, [userLists, selectedList]);

    const handleGenresChange = (newSelectedGenres) => {
        setSelectedGenres(newSelectedGenres);
        onGenresChange(newSelectedGenres);
    };

    return (
        <StyledFormControl fullWidth sx={{ marginTop: 2 }}>
            <StyledInputLabel>Exclude Genres</StyledInputLabel>
            <StyledSelect
                fullWidth
                multiple
                label="Exclude Genres"
                value={selectedGenres}
                onChange={(e) => handleGenresChange(e.target.value)}
                renderValue={(selected) => (
                    <>
                        {selected.map((value) => (
                            <StyledMenuItem key={value}>{value}</StyledMenuItem>
                        ))}
                    </>
                )}
                MenuProps={{
                    PaperProps: { style: { maxHeight: 105 } },
                }}
            >
                {genresWithoutNone.map((genre) => (
                    <StyledMenuItem value={genre} key={genre}>
                        {genre}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
        </StyledFormControl>
    );
};

export default SelectGenres;
