import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Auth } from "./pages/auth";
import { HomeContainer } from "./containers";
import { verifyUser } from "./utils/authFunctions";
import { SnackBarProvider } from "./utils/snackbarContext";

import ConsecutiveSnackbar from "./utils/snackbar";
import Dashboard from "./pages/dashboard/dashboardPage";
import DashboardRoutes from "./pages/dashboard/routes/dashboardRoutes";
import NotFound from "./pages/404";
import LoadingOverlay from "./pages/loading";



import { useDiscoverContext } from "./context/dashboardContext";

function App() {
    const [cookies, setCookie, removeCookie] = useCookies(["authToken", "authUser"]);
    const [isAuthed, setIsAuthed] = useState(null);
    const [loading, setLoading] = useState(true);
    const {setUserLists} = useDiscoverContext();

    useEffect(() => {
        verifyUser(cookies, removeCookie, setIsAuthed, setLoading, setUserLists);
    }, [cookies]);

    if (loading) {
        return <LoadingOverlay />;
    }

    return (
        <Router>
            <SnackBarProvider>
                <ConsecutiveSnackbar />
                <Routes>
                    <Route path="/" element={isAuthed ? <Dashboard /> : <Auth />}>
                        <Route index element={<HomeContainer />} />
                        <Route path="dashboard/*" element={<DashboardRoutes />} />
                        <Route path="/login" to="/dashboard" element={<Dashboard />}></Route>
                        <Route path="/register" to="/dashboard" element={<Dashboard />}></Route>
                    </Route>

                    {!isAuthed && (
                        <>
                            <Route path="/login" element={<Auth />} />
                            <Route path="/register" element={<Auth />} />
                        </>
                    )}

                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </SnackBarProvider>
        </Router>
    );
}

export default App;
