import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Connection } from "../";
import { TraktIcon } from "../../../../common/icons";
const TraktSettings = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [cookies] = useCookies(["authUser"]);

    const apiUrl = process.env.REACT_APP_API_URL;
    const baseEndpoint = "trakt";
    const username = cookies.authUser || "";

    // Conditionally set the endpoint based on isVerified
    const endpoint = isVerified ? `${baseEndpoint}/oauth/revoke` : `${baseEndpoint}/oauth`;

    const url = `${apiUrl}${endpoint}?username=${encodeURIComponent(username)}`;

    useEffect(() => {
        const verifyTraktAccess = async () => {
            try {
                const verifyUrl = apiUrl + "trakt/oauth/verify";

                const response = await fetch(verifyUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ username }),
                });

                setIsVerified(response.ok);
            } catch (error) {
                console.error("Error verifying Trakt access:", error);
                setIsVerified(false);
            }
        };

        verifyTraktAccess();
    }, [username, apiUrl]);

    return <Connection serviceName="Trakt" iconSrc={TraktIcon} href={url} isVerified={isVerified} />;
};

export default TraktSettings;
