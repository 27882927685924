import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

import "./styles.css";
import { destroySession } from "../../../../utils/authFunctions";
import { AvatarIcon } from "../../../icons";

const StyledMenuItem = ({ children, onClose }) => (
    <MenuItem
        className="avatar-navlink"
        style={{
            textDecoration: "none",
            color: "inherit",
            "&:hover": { backgroundColor: "transparent" },
        }}
        sx={{
            width: "150px",
            fontFamily: "Montserrat, sans-serif",
            fontSize: "0.9rem",
        }}
        onClick={() => {
            onClose();
        }}
    >
        {children}
    </MenuItem>
);

const AvatarMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [anchorEl, setAnchorEl] = useState(null);
    const [cookies, , removeCookie] = useCookies();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    const handleLogout = async () => {
        await destroySession(cookies);
        removeCookie("authToken", { path: "/" });
        removeCookie("authUser", { path: "/" });
        navigate("/");
    };

    return (
        <div className="dropdown">
            <div style={{ position: "relative" }}>
                <Avatar
                    alt="User Avatar"
                    src={AvatarIcon}
                    onClick={handleClick}
                    style={{ cursor: "pointer", zIndex: "999" }}
                />
                <Menu
                    sx={{
                        top: "-50px",
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                >
                    <Link to="dashboard/profile">
                        <StyledMenuItem to="dashboard/profile" onClose={handleClose}>
                            <PersonIcon className={activeLink === "/profile" ? "icon active" : "icon"} />
                            Profile
                        </StyledMenuItem>
                    </Link>

                    <Link to="dashboard/settings">
                        <StyledMenuItem to="dashboard/settings" onClose={handleClose}>
                            <SettingsSuggestIcon className={activeLink === "/settings" ? "icon active" : "icon"} />
                            Settings
                        </StyledMenuItem>
                    </Link>

                    <StyledMenuItem onClose={handleLogout}>
                        <LogoutIcon className="icon" />
                        Sign out
                    </StyledMenuItem>
                </Menu>
            </div>
        </div>
    );
};

export default AvatarMenu;
