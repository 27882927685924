import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { styled, Button } from "@mui/material";
import { useDiscoverContext } from "../../../context/dashboardContext";
import { useLocation } from "react-router-dom";
import getStyledContainer from "../../../styles/containers/catalogs";
import SelectionDropdown from "./dropdown";
import DialogComponent from "./dialogs";
import { Category } from "@mui/icons-material";

const SelectionContainer = ({ onListChange, onGenreChange }) => {
    const StyledContainer = getStyledContainer();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const isMobile = useMediaQuery("(max-width:580px)");
    const isWideScreen = useMediaQuery("(min-width:985px)");
    const location = useLocation();

    // context states
    const {
        userLists,
        setUserLists,

        categories,
        setCategories,

        selectedCategory,
        setSelectedCategory,

        filteredLists,
        setFilteredLists,

        selectedList,
        setSelectedList,

        genres,
        setGenres,

        selectedGenre,
        setSelectedGenre,
    } = useDiscoverContext();

    // params from url
    const { category, listID } = useParams();

    // used to update the item grid based on selectedList
    useEffect(() => {
        onListChange(selectedList);
    }, [selectedList, onListChange]);

    // used to update the item grid, based on selectedGenre
    useEffect(() => {
        onGenreChange(selectedGenre);
    }, [selectedGenre, onGenreChange]);

    useEffect(() => {
            window.history.pushState(null, "", `/dashboard/discover/${selectedCategory}/${selectedList}`);
    }, [selectedCategory, selectedList]);

    useEffect(() => {
        if (category && listID) {
            setSelectedCategory(category);
            setSelectedList(Number(listID));
        }
    }, [category, listID]);


    // used to build the categories and select the first one
    // only runs on mount so category changes does not effect this
    useEffect(() => {
        const updateUserLists = () => {
            const filteredLists = [...new Set(userLists.map((list) => capitalizeFirstLetter(list.type)))];
            setCategories(filteredLists);

            if (!selectedCategory && !category) {
                setSelectedCategory(filteredLists[0]);
            } 

            else if (category) {
                setSelectedCategory(category);
            }
            
            else if (selectedCategory) {
                setSelectedCategory(selectedCategory);
            }
        };

        updateUserLists();

    }, [userLists]);

    // used to build the user lists and select the first one
    // run every time the selectedCategory changes
    useEffect(() => {
        const updateFilteredLists = () => {
            if (selectedCategory) {

                let filteredLists = userLists.filter((list) => {
                    return capitalizeFirstLetter(list.type) === selectedCategory;
                });

                setFilteredLists(capitalizeFirstLetterForLists(filteredLists));

                if (!selectedList) {
                    setSelectedList(filteredLists[0].id);
                } 
                
                else {

                    const isSelectedList = filteredLists.find((list) => {
                        console.log(list);
                        return list.id === Number(selectedList)
                    }) 

                    if (isSelectedList) {
                        setSelectedList(Number(selectedList));
                    }

                    else {
                        setSelectedList(filteredLists[0].id)
                    }
                }                
            }
        };

        updateFilteredLists();
    }, [userLists, selectedCategory, listID, category]);

    // used to build the lists genres and select the first one
    useEffect(() => {
        const updateListGenres = () => {
            if (selectedList) {
                const selectedListOBJ = userLists.find((list) => {
                    return list.id === Number(selectedList);
                })
                const listGenres = selectedListOBJ ? selectedListOBJ.genres : [];
                const genresWithNone = ["None", ...listGenres.map(capitalizeFirstLetter)];
                setGenres(genresWithNone);
                setSelectedGenre(genresWithNone[0]);
            }
        };

        updateListGenres();

    }, [selectedList]);

    // helper function for one string
    const capitalizeFirstLetter = (string) => {
        return isNaN(string.charAt(0)) ? string.charAt(0).toUpperCase() + string.slice(1) : string;
    };

    // helper function for lists
    const capitalizeFirstLetterForLists = (lists) => {
        return lists.map((list) => ({
            ...list,
            name: capitalizeFirstLetter(list.name),
        }));
    };

    // handle dialog open
    const handleDialogToggle = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    const FixedButtonWrapper = styled("div")({
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        position: "fixed",
        right: "20px",
        width: "100%",
    });

    const FixedButton = styled(Button)({
        marginTop: "2px",
        height: "41px",
        position: "fixed",
        right: "28px",
    });

    return (
        <StyledContainer>
            <SelectionDropdown
                options={categories.map((type) => ({
                    value: type,
                    label: type,
                }))}
                labelText="Type"
                selectedValue={selectedCategory}
                onSelect={(value) => setSelectedCategory(value)}
                isMobile={isMobile}
            />
            <SelectionDropdown
                options={filteredLists.map((list) => ({
                    value: list.id,
                    label: list.name,
                }))}
                labelText="List"
                selectedValue={selectedList}
                onSelect={(value) => setSelectedList(value)}
                isMobile={isMobile}
            />
            <SelectionDropdown
                options={genres.map((genre) => ({
                    value: genre,
                    label: genre,
                }))}
                labelText="Genre"
                selectedValue={selectedGenre}
                onSelect={(value) => setSelectedGenre(value)}
                isMobile={isMobile}
            />

            <>
                {isWideScreen && (
                    <FixedButtonWrapper>
                        {/* Call handleDialogToggle when the button is clicked */}
                        <FixedButton variant="contained" color="primary" onClick={handleDialogToggle}>
                            EDIT LIST
                        </FixedButton>
                    </FixedButtonWrapper>
                )}
            </>

            <DialogComponent open={isDialogOpen} onClose={handleDialogToggle} />
        </StyledContainer>
    );
};

export default SelectionContainer;
