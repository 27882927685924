import { styled } from "@mui/system";
import { TextField } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        backgroundColor: "rgb(15, 13, 32)",
        color: "rgb(154, 152, 166)",
    },

    "& .MuiInputLabel-root": {
        color: "rgb(154, 152, 166)",
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#553fa8",
    },
}));

export default StyledTextField;
