import React, { useState } from "react";
import { Grid, DialogContent, IconButton, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { CustomDialog } from "../../styles/frames/contentInformation";
import CloseIcon from "@mui/icons-material/Close";
import {
    CustomContainer,
    CustomButton,
    CustomGrid,
    CustomTypography,
    Image,
    ImageContainer,
} from "../../styles/containers/catalogs2";

const MovieGrid = ({ data, label, url }) => {
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleImageError = (event, index) => {
        setLoading(false);
    };

    const handleImageClick = (id, type) => {
        const selectedImageUrl = `https://web.stremio.com/#/detail/${type}/${id}`;
        setSelectedImageUrl(selectedImageUrl);
        setLoading(true);
    };

    const handleCloseDialog = () => {
        setSelectedImageUrl(null);
        setLoading(false);
    };

    return (
        <CustomContainer>
            <Grid container justifyContent="space-between" alignItems="center">
                <CustomTypography variant="h6">{label}</CustomTypography>
                <Link to={`/dashboard/discover/${url}`}>
                    <CustomButton variant="contained" color="primary">
                        Show all
                    </CustomButton>
                </Link>
            </Grid>

            {/* Grid layout */}
            <CustomGrid>
                {data.map((item) => (
                    <ImageContainer key={item.id} onClick={() => handleImageClick(item.id, item.type)}>
                        {loading ? (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                sx={{
                                    borderRadius: "inherit",
                                    objectFit: "cover",
                                    boxSizing: "border-box",
                                }}
                            />
                        ) : (
                            <Image
                                src={item.poster}
                                alt={item.name}
                                onError={(e) => handleImageError(e, item.id)}
                                onLoad={() => setLoading(false)}
                            />
                        )}
                    </ImageContainer>
                ))}
            </CustomGrid>

            {/* Dialog */}
            <CustomDialog
                open={!!selectedImageUrl}
                onClose={handleCloseDialog}
                style={{ width: "100%", height: "100%" }}
            >
                <DialogContent>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseDialog}
                        sx={{ position: "absolute", bottom: "8px", right: "28px", fontSize: "25px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {selectedImageUrl && (
                        <iframe
                            title="Embedded Website"
                            src={selectedImageUrl}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                        />
                    )}
                </DialogContent>
            </CustomDialog>
        </CustomContainer>
    );
};

export default MovieGrid;
