import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Topbar, Sidebar, Bottombar } from "../../common/dashboard";
import DashboardRoutes from "./routes/dashboardRoutes";
import "./styles.css";

function Dashboard() {
    const location = useLocation();

    if (location.pathname === "/login" || location.pathname === "/register") {
        return <Navigate to="/dashboard" />;
    }

    return (
        <>
            <Topbar />
            {useMediaQuery("(max-width: 640px)") ? <Bottombar /> : <Sidebar />}
            <Outlet />
            <DashboardRoutes />
        </>
    );
}

export default Dashboard;
