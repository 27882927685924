import Navbar from "../../common/auth/navbar";
import "./styles.css";

export default () => {
    return (
        <>
            <Navbar />
            <div className="error-container">
                <div className="error-header">
                    <p className="paragraph center">404</p>

                    <p className="paragraph center">NOT FOUND</p>

                    <p className="paragraph center">&#128148;</p>
                </div>
            </div>
        </>
    );
};
