import SvgIcon from "@mui/material/SvgIcon";

const HomeIcon = () => {
    return (
        <SvgIcon>
            <svg className="home" viewBox="0 0 512 512">
                <path d="M260.4 99a7.983 7.983 0 0 0-5.55-2.25c-2.072 0-4.063 0.81-5.55 2.25l-184.8 176.5a7.944 7.944 0 0 0-2.5 5.8v165.7a32.13 32.13 0 0 0 32.1 32.1h96.5c2.114 0 4.208-0.41 6.162-1.22 1.954-0.81 3.729-2 5.224-3.49a16.11 16.11 0 0 0 4.714-11.39v-136.5a8.024 8.024 0 0 1 8-8H295a8.024 8.024 0 0 1 8 8v136.5c0 4.27 1.694 8.37 4.712 11.39A16.086 16.086 0 0 0 319.1 479.0999999999999h96.4c8.51-0.01 16.67-3.39 22.69-9.41s9.4-14.18 9.41-22.69v-165.7c0-1.09-0.22-2.16-0.65-3.16-0.43-1-1.06-1.9-1.85-2.64l-184.7-176.5Z"></path>
                <path d="M490.70000000000005 242.4000000000001l-75.1-71.8a942.475 942.475 0 0 0-80.3-76.9l-58.1-55.6a31.693 31.693 0 0 0-22.2-8.7c-4.095-0.11-8.171 0.6-11.986 2.09A30.527 30.527 0 0 0 232.79999999999995 38.09999999999991l-213.5 204.3a16.432 16.432 0 0 0-1.3 22.5 16.164 16.164 0 0 0 4.938 3.84c1.882 0.94 3.931 1.5 6.03 1.65 2.099 0.14 4.207-0.12 6.203-0.79 1.996-0.67 3.84-1.72 5.429-3.1l0.6-0.6 208.3-199a7.983 7.983 0 0 1 5.55-2.25c2.072 0 4.063 0.81 5.55 2.25l208.3 199c3.08 2.93 7.18 4.53 11.43 4.45 4.25-0.07 8.3-1.82 11.27-4.85a16.582 16.582 0 0 0-0.9-23.1Z" />
            </svg>
        </SvgIcon>
    );
};

export default HomeIcon;
