import { useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { TopBar, OptionsList, StyledListItemIcon, CustomDialog } from "./styles/dialogs/catalogSelection";

const MobileDialog = () => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <div>
            <Button onClick={handleOpenDialog}>Open Dialog</Button>
            <CustomDialog open={dialogOpen} onClose={handleCloseDialog}>
                <TopBar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseDialog}>
                        <ArrowBackIosNewIcon sx={{ fontSize: ".9rem" }} />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                        Type
                    </Typography>
                </TopBar>

                <OptionsList>
                    <ListItem button>
                        <ListItemText primary="Option 1" primaryTypographyProps={{ fontSize: "14px" }} />

                        <StyledListItemIcon>
                            <FiberManualRecordIcon sx={{ fontSize: ".8rem", color: "#7c5cf5" }} />
                        </StyledListItemIcon>
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Option 2" primaryTypographyProps={{ fontSize: "14px" }} />
                        <StyledListItemIcon>
                            <FiberManualRecordIcon sx={{ fontSize: ".8rem", color: "#7c5cf5" }} />
                        </StyledListItemIcon>
                    </ListItem>
                </OptionsList>
            </CustomDialog>
        </div>
    );
};

export default MobileDialog;
